.info-title-card {
  display: flex;
  align-items: center;
}

.info-text-card {
  font-family: "Inter UI";
  font-size: 1rem;
  color: #75819b;
  font-weight: 700;
  margin-right: 5px;
}
