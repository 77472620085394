@import '../_global-vars.css';

.reset {
  display: flex;
  flex-direction: column;
}

.reset-form {
  list-style: none;
  width: 450px;
}
