@import '../_global-vars.css';

.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background: white;
  border-radius: 10px;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__close-btn {
  display: block;
  padding: 6px 9px;
  border-radius: 8px;
  border: solid 1px var(--color-inactive-input);
  cursor: pointer;
  color: var(--color-light-text);
}

.modal__close-btn:hover {
  display: block;
  border-color: var(--color-dark-text);
}

.modal__content {
  /* padding: 1rem 0.5rem; */
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 30rem);
    top: calc(50% - 18rem);
    width: 60rem;
    min-height: 36rem;
  }
}

.modal-enter {
  transform: translateY(-5rem);
  opacity: 0;
  transition: all 300ms;
  transition-timing-function: ease-out;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 300ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 300ms;
  transition-timing-function: ease-out;
}
