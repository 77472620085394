.date-picker-created .react-datepicker-wrapper input {
  border: none;
  background: transparent;
  color: #75819b;
  font-family: "Inter UI";
  font-weight: 500;

  padding: 5px;
  border-radius: 8px;
  width: 90px;
  cursor: pointer;
}

/* deadline passed  */
.date-picker-deadline-passed .react-datepicker__input-container::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
}

.date-picker-deadline-passed .react-datepicker__input-container {
  background: #f87661;
  /* font-weight: bold; */
  color: white;
  padding: 0px 5px 0px 10px;
  border-radius: 8px;
  width: 90px;
  position: relative;
}

.date-picker-deadline-passed .react-datepicker-wrapper input {
  border: none;
  background: none;
  /* font-weight: bold; */
  color: white;
  padding: 5px;
  border-radius: 8px;
  width: 90px;
  cursor: pointer;
}

.date-picker-deadline-passed .react-datepicker-wrapper input:focus {
  outline: none;
}

/* in time  */
.date-picker-deadline-in-time .react-datepicker__input-container::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: grey;
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
}

.date-picker-deadline-in-time .react-datepicker__input-container {
  background: transparent;
  /* font-weight: bold; */
  color: #ebebeb;
  padding: 0px 5px 0px 10px;
  border-radius: 8px;
  width: 90px;
  position: relative;
}

.date-picker-deadline-in-time .react-datepicker-wrapper input {
  border: none;
  background: none;
  /* font-weight: bold; */
  color: #384359;
  padding: 5px;
  border-radius: 8px;
  width: 90px;
  cursor: pointer;
}

.date-picker-deadline-in-time .react-datepicker-wrapper input:focus {
  outline: none;
}

/* deadline close  */
.date-picker-deadline-close .react-datepicker__input-container::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #f19fa3;
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
}

.date-picker-deadline-close .react-datepicker__input-container {
  background: #fff0f0;
  /* font-weight: bold; */
  color: #f19fa3;
  padding: 0px 5px 0px 10px;
  border-radius: 8px;
  width: 90px;
  position: relative;
}

.date-picker-deadline-close .react-datepicker-wrapper input {
  border: none;
  background: none;
  /* font-weight: bold; */
  color: #f19fa3;
  padding: 5px;
  border-radius: 8px;
  width: 90px;
  cursor: pointer;
}

.react-datepicker-wrapper input:focus {
  outline: none;
}

.date-picker-start input,
.date-picker-end input {
  height: 35px;
  border-radius: 10px;
  border: 2px solid gray;
  padding: 0 8px;
}

.MuiAccordionDetails-root {
  justify-content: center;
  align-items: center;
}

.range-dates-input {
  width: 100%;
}
