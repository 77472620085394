.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #ff0055;
  border-radius: 4px;
  background: #ff0055;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #ff4382;
  border-color: #ff4382;
}

.button--inverse {
  background: transparent;
  color: #ff0055;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ff0055;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #e5e5e5;
  color: #ACACAC;
  border-color: #e5e5e5;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

.button--full-size {
  border: 1px solid #6E78F7;
  border-radius: 4px;
  background: #6E78F7;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 20px;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  width: 100%;
}

.button--passive {
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  color: #75819B;
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 20px;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  width: 100%;
}

.button--full-size:hover {
  background: #7e86f7;
  border: 1px solid #6E78F7;
}

.button--passive:hover {
  background: #fafafa;
  border: 1px solid #D9E1E9;
}