.side-topic-coverage {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.side-topic-coverage-category {
  margin-top: 0px;
}

.side-topic-divider {
  background-color: #ededed;
  align-self: center;
  height: 1px;
  width: 80%;
}

.side-topic-info {
  padding: 25px 20px 25px 25px;
  align-items: flex-start;
}

.side-topic-info h3 {
  font-family: "Graphik";
  font-weight: 600;
  color: #384259;
  padding-bottom: 10px;
}

.side-topic-info p {
  font-family: "Inter UI";
  font-weight: 500;
  color: #b5b5c3;
  font-size: 13px;
  margin-top: 10px;
}
