.skeleton-head-competition {
  display: flex;
  flex-direction: column;
  padding: 10px 0 30px;
}

.skeleton-head-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.skeletion-head-results {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 15px;
}

.skeletion-head-details {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.skeleton-head-competition .react-loading-skeleton {
  border-radius: 10px;
  line-height: 0;
}
