.competition-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 10px;
}

.competition-body {
  width: 100%;
  display: flex;
  align-items: center;
}

.circle-position {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.green {
  background-color: #6ed692;
}

.yellow {
  background-color: #f7ca52;
}

.orange {
  background-color: #ef995d;
}

.red {
  background-color: #e898a2;
}

.item-position {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  position: relative;
}

.number-position {
  position: absolute;
  top: 20%;
  font-size: 14px;
  font-family: "Circular Std Book";
  font-weight: bold;
  text-align: center;
  color: white;
}

.item-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: 30px;
}

.item-top {
  display: flex;
  justify-content: flex-start;
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
  color: #adb1b8;
  padding-bottom: 10px;
}

.item-top a {
  display: flex;
  justify-content: flex-start;
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
  color: #adb1b8;
}

.item-top-stats {
  padding: 0 5px;
  border-right: solid 1px #ededed;
}

.item-top :last-child,
.item-top :first-child {
  border: none;
}

.item-top :first-child {
  text-decoration: underline;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.item-top :first-child img {
  padding-left: 3px;
}

.competition-title {
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
  color: #384259;
  padding-bottom: 5px;
}

.competition-desc {
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 10px;
  color: #a2a7b0;
  padding-bottom: 10px;
}

.competition-item-divider {
  background-color: #ededed;
  align-self: center;
  height: 1px;
  width: 90%;
}

.headings-info {
  padding: 0px 10px 10px 40px;
}

.expand-headings,
.hide-headings {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
}

.hide-headings {
  margin-top: 10px;
}

.expand-headings-text,
.hide-headings-text {
  font-family: "Graphik";
  font-weight: 400;
  font-size: 12px;
  color: #a2a7b0;
  padding-left: 5px;
}

.expand-headings:hover .expand-headings-text {
  color: #6a71f6;
}

.hide-headings:hover .hide-headings-text {
  color: #6a71f6;
}
