.popup-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-title {
  color: #384259;
  font-family: "Graphik";
  font-size: 22px;
  line-height: 50px;
  padding-bottom: 20px;
}

.close-pop-button {
  display: block;
  padding: 6px 9px;
  border-radius: 8px;
  border: solid 1px var(--color-inactive-input);
  cursor: pointer;
  color: var(--color-light-text);
  height: 32px;
  margin-right: -19px;
  margin-top: -19px;
}
