.info-card {
  /* justify-content: space-between; */
  display: flex;
  align-items: center;
  padding: 20px;
  /* min-width: 300px; */
  flex: 1;
}

.info-card-content-wrapper {
  display: flex;
  flex-direction: column;
}

.card-icon svg {
  fill: #1a1d1f;
  height: 24px;
  width: 24px;
}

.main-icon {
  padding: 10px;
  display: flex;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
}

.info-paper {
  display: flex;
  padding: 10px;
}

.card-icon-title {
  margin-top: 20px;
}

.card-data-content {
  margin-bottom: 20px;
}
