:root {
  --color-dark-text: #384359;
  --color-light-text: #75819b;
  --color-active-input: #6870f6;
  --color-inactive-input: #d9e1e9;
  --color-error-input: #ec5032;
  --color-success-input: #66ca95;

  --fancy-font: "GT Super Display";
  --fancy-font-size: 26px;
  --fancy-font-line-height: 40px;

  --default-font: "Inter UI";
}
