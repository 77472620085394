/* Basic editor styles */
.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror h1.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  line-height: 1.1;
  font-family: "Inter UI";
  font-weight: 900;
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2 ,
.ProseMirror h3,
.ProseMirror h4 ,
.ProseMirror h5 ,
.ProseMirror h6 {
  line-height: 1.1;
  font-family: "Inter UI";
  font-weight: 900;
  color: #384259;
}

.ProseMirror h1 > *,
.ProseMirror h2 > *,
.ProseMirror h3 > *,
.ProseMirror h4 > *,
.ProseMirror h5 > *,
.ProseMirror h6 > * {
  line-height: 1.1;
  font-family: "Inter UI";
  font-weight: 900;
  color: #384259;
}

.ProseMirror p {
  font-family: "Inter UI";
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
  color: var(--color-light-text);
}

.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

.editor-wrapper {
  height: calc(100vh - 144px); 
}

.editor-wrapper .ProseMirror {
  margin: 0 auto;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  height: calc(100vh - 200px);
  width: 100%;
  padding: 60px 90px;
  overflow: scroll;
}

.ProseMirror:focus {
  outline: none;
}
