.title-with-icon {
  display: flex;
  align-items: center;
}

.title-name {
  font-weight: bold;
  font-size: 15px;
  color: var(--color-dark-text);
  margin-right: 5px;
}
