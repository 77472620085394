.progress-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
}

.wordcount-progress {
  width: 45%;
}

.keywords-progress {
  width: 45%;
}

.progress-title {
  font-family: "Graphik";
  color: var(--color-light-text);
  margin-bottom: 8px;
}

.progress-value {
  font-family: var(--default-font);
  color: var(--color-light-text);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 2px;
}

.eshop-progress-bar {
  width: 100%;
  height: 15px;
  border-radius: 20px;
  background-color: #e8ebee;
}
