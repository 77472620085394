.source-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.source-top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.top-name a {
  margin-right: 5px;
  font-weight: bold;
  color: var(--color-active-input);
}

.top-percent {
  display: flex;
  font-size: 12px;
  font-style: italic;
  margin-right: 10px;
  color: #a2a2a2;
}

.top-btn a {
  font-size: 13px;
  color: #384259;
  font-weight: bold;
  text-decoration: underline;
}

.source-bottom {
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
}

.source-bottom a {
  color: #808080;
}

.top-btn a:hover {
  color: var(--color-active-input);
}
