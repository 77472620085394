.delete-article-name {
  color: #ec5032;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.delete-confirmation-controls {
  display: flex;
}

.confirm-all-titles {
  font-size: 18px;
  font-weight: 600;
  color: #384259;
  margin-bottom: 10px;
}
