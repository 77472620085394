.main-tips-wrapper {
  background: white;
  border-radius: 10px;
  max-width: 960px;
  min-width: 300px;

  display: flex;
  flex-direction: column;
  padding-bottom: 73px;
}

.top-bar-control {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 73px;
}

.tips-content {
  width: 100%;
  display: flex;
}

.close-info-window {
  width: 230px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-tips-button {
  display: block;
  padding: 6px 9px;
  border-radius: 8px;
  border: solid 1px var(--color-inactive-input);
  cursor: pointer;
  color: var(--color-light-text);
  height: 32px;
  margin-right: 20px;
  margin-top: 20px;
}

.close-tips-button:hover {
  display: block;
  border-color: var(--color-dark-text);
}

.close-tips-text {
  margin-right: 5px;
  font-size: 13px;
  color: var(--color-error-input);
}

.close-tips-timer {
  margin-right: 5px;
  font-size: 13px;
  color: #75819b;
  margin-left: 5px;
}

.left-side-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 80px;
}

.title-tips {
  font-family: var(--fancy-font);
  font-size: 28px;
  padding-right: 5px;
  margin-bottom: 20px;
}

.info-bullet-img {
  margin-right: 20px;
}

.info-bullets {
  margin: 10px 0;
  list-style: none;
  display: flex;
  align-items: center;
}
