.dashboard {
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: #fafbff;
  padding-bottom: 80px;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}

.table-wrapper {
  margin: 0 30px 30px;
  padding: 50px 30px 30px;
  background-color: white;
  border-radius: 20px;
  /* min-width: 1495px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26); */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  min-height: 843px;
}

.dashboard-topbar {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 20px;
  font-size: 14px;
}

.topbar-left {
  display: flex;
}

.dropdown-menu,
.dropdown-menu-single {
  width: 150px;
  margin-right: 30px;
}

.dashboard-topbar .react-select__control {
  background: #f8f9fb;
  border: none !important;
}

.dashboard-topbar .react-select__menu {
  padding: 5px 10px;
  border: none !important;
  font-size: 13px;
  border-radius: 10px;
  outline: none !important;
}

.dropdown-menu .react-select__menu,
.dropdown-menu-single .react-select__menu {
  width: 210px;
}

.dashboard-topbar .react-select__menu-list {
  /* background: yellow; */
  /* border-radius: 20px; */
}

.dashboard-topbar .react-select__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: white;
  color: #384359;
}

.dropdown-menu .react-select__option:last-child {
  border-top: solid 1px #f2f0f9;
  color: #d20000;
}

.dropdown-menu-single .react-select__option:last-child {
  color: #d20000;
}

.dashboard-topbar .react-select__option:hover {
  background: #f2f0f9;
  border-radius: 6px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.add-new-button {
  max-width: 200px;
  background: #6870f6;
  border: none;
  color: white;
  font-weight: 600;
  border-radius: 12px;
  padding: 0 10px;
  cursor: pointer;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  margin-right: 30px;
}

.clear-filters-button {
  display: flex;
  align-items: center;
  margin-right: 25px;
  cursor: pointer;
}

.clear-filters-text {
  margin-right: 5px;
  color: #75819b;
}

.add-new-button:hover {
  background: #7e86f7;
}

.add-new-button:focus {
  outline: none;
}

.topbar-right {
  display: flex;
  align-items: center;
}

.topbar-right .react-select-container {
  width: 220px;
  margin-right: 10px;
}

.bottom-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 20px;
  padding: 0 30px;
}

.copyright-info {
  color: #8f95b2;
  font-size: 13px;
  font-family: "Inter UI";
  font-weight: 500;
}

.dynamic-icon-bottom-expanded-logout {
  display: flex;
  align-items: center;
  cursor: pointer;
}
