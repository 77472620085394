@import "../_global-vars.css";

.register-component {
  display: flex;
  align-items: center;
  padding: 80px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 15px;
  background: white;
}

.register-app-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.register-app-logo p {
  font-family: var(--default-font);
  color: var(--color-light-text);
  margin-top: 10px;
}

.register-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 96px;
  max-width: 600px;
  width: 100%;
}

.register-divider {
  background-color: var(--color-inactive-input);
  height: 450px;
  width: 1px;
}

.register-right-content {
  padding: 0 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.register-title-copy {
  text-align: center;
  font-size: 32px;
  color: #2e2e2e;
  font-family: "Graphik";
  font-weight: 900;
  margin-bottom: 18px;
}

.register-subcopy {
  text-align: center;
  color: #2e2e2e;
  font-family: "Inter UI";
  font-size: 14px;
  line-height: 20px;
}