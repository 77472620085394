.topic-head-skeleton {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.topic-category-head-top {
  display: flex;
  padding: 0 25px 25px;
  justify-content: space-between;
}

.topic-article-head-top {
  display: flex;
  flex-direction: column;
  padding: 0 25px 25px;
}

.topic-article-text {
  padding: 25px 0 5px;
  width: 100%;
}

.topic-article-switch-area {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.topic-head-skeleton .react-loading-skeleton {
  border-radius: 10px;
  line-height: 0;
}

.topic-article-text span {
  display: block;
  margin-bottom: -5px;
}
