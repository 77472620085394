.performance-stats-small {
  padding: 40px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  min-width: 40%;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.icon-wrapper {
  padding: 4px 7px;
  background: #ffa2bf;
  border-radius: 10px;
  margin-right: 10px;
}

.icon-wrapper-view {
  padding: 4px 7px;
  background: #3f8cfe;
  border-radius: 10px;
  margin-right: 10px;
}

.icon-with-title {
  display: flex;
  align-items: center;
  margin-left: -7px;
}

.stats-card-title {
  font-size: 17px;
  font-weight: 500;
  color: #6F767E;
  font-family: var(--default-font);
}

.chart-info {
  display: flex;
  align-items: flex-end;
}

.current-value {
  color: #11142D;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 40px;
  padding-right: 50px;
}

.chart-bottom-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.perf-icon-up-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  color: rgb(84, 214, 44);
  background-color: rgba(84, 214, 44, 0.16);
}

.perf-icon-down-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  color: rgb(255, 72, 66);
  background-color: rgba(255, 72, 66, 0.16);
}

.perc-value {
  color: #11142D;
  font-family: 'Poppins';
  font-weight: 500;
  padding-right: 5px;
}
