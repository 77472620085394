@font-face {
  font-family: "GT Super Display";
  src: url("./fonts/GTSuperDisplay-Bold.woff2") format("woff2"),
    url("./fonts/GTSuperDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT Super Display";
  src: url("./fonts/GTSuperDisplay-Medium.woff2") format("woff2"),
    url("./fonts/GTSuperDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/InterUI-Black.woff2") format("woff2"),
    url("./fonts/InterUI-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT Super Display";
  src: url("./fonts/GTSuperDisplay-Regular.woff2") format("woff2"),
    url("./fonts/GTSuperDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/InterUI-Bold.woff2") format("woff2"),
    url("./fonts/InterUI-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/InterUI-Regular.woff2") format("woff2"),
    url("./fonts/InterUI-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/InterUI-Medium.woff2") format("woff2"),
    url("./fonts/InterUI-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/Inter-Black.woff2") format("woff2"),
    url("./fonts/Inter-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter UI";
  src: url("./fonts/Inter-SemiBold.woff2") format("woff2"),
    url("./fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("./fonts/Graphik-Bold.woff2") format("woff2"),
    url("./fonts/Graphik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("./fonts/Graphik-Semibold.woff2") format("woff2"),
    url("./fonts/Graphik-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Std Book";
  src: url("./fonts/CircularStd-Book.woff2") format("woff2"),
    url("./fonts/CircularStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Copperplate";
  src: url("./fonts/Copperplate.woff2") format("woff2"),
    url("./fonts/Copperplate.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.woff2") format("woff2"),
    url("./fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Medium.woff2") format("woff2"),
    url("./fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.woff2") format("woff2"),
    url("./fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body * {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter UI";
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}
a {
  color: #38a5ee;
  text-decoration: none;
}

.beamer_defaultBeamerSelector {
  bottom: 40px !important;
}
