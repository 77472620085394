.table-of-contents {
  color: #6a71f6;
  font-family: "Graphik";
  font-weight: 500;
  font-size: 14px;
  margin: 10px 0;
}

.h1-article-heading {
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #384359;
  line-height: 23px;
  margin-right: 30px;
}

.h2-article-heading {
  font-size: 13px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #384359;
  line-height: 23px;
  margin-left: 10px;
  margin-right: 30px;
}

.h3-article-heading {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #384359;
  line-height: 23px;
  margin-left: 20px;
  margin-right: 30px;
}
