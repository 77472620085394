.performance-stats-big {
  
  border-radius: 20px;
  min-width: 62%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  flex-direction: column;
  background: white;
}

.big-stats-title {
  font-size: 22px;
  color: #1A1D1F;
  font-weight: 600;
  font-family: var(--default-font);
}

.big-stats-project {
  font-size: 18px;
  color: #6F767E;
  font-weight: 500;
  font-family: var(--default-font);
}

.stats-big-top {
  padding: 40px;
  display: flex;
  justify-content: space-between;
}

.stats-split {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.stats-split-3 {
  display: flex;
  align-items: center;
}

.stats-big-content {
  display: flex;
}

.health-wrapper {
  width: 30%;
}

.health-stats {
  position: relative;
  padding: 40px 40px 0 40px;
}

.health-comparison {
  display: flex;
  justify-content: center;
}

.health-stats-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translateY(-14%);
  font-size: 30px;
  font-weight: 800;
  color: #11142D;
  font-family: 'Poppins';
  line-height: 1;
}

.health-stats-info {
  font-weight: normal;
  color: #11142D;
  font-size: 14px;
}

.opportunity-stats {
  display: flex;
  padding: 0 40px;
  justify-content: flex-end;
  flex: 1;
  border-right: solid 1px #f1f1f1;
} 

.opportunity-block {
  display: flex;
  flex-direction: column;
}

.stats-details-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info-text-wrapper {
  margin-top: -10px;
}

.opportunity-value {
  color: #6C5DD3;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 70px;
}

.value-info {
  font-size: 17px;
  font-weight: 500;
  color: #6F767E;
  font-family: var(--default-font);
  margin-top: -14px;
}