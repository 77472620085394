.form-wrapper {
  padding: 0 10px; /* scroolbar space */
}
.new-description-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.new-description-form .form-control {
  margin: 0;
}

.topic-and-deadline {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.topic-and-deadline .form-control {
  width: 80%;
}

.desc-date-wrapper {
  height: 38px;
  align-items: center;
}

.desc-date-input-container {
  align-items: center;
  color: #384359;
  justify-content: space-between;
}

.desc-date-input-label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 11px;
}

.desc-date-input-container
  .date-picker-created
  .react-datepicker-wrapper
  input {
  padding: 10;
  border: solid 1px var(--color-inactive-input);
  text-align: center;
}
