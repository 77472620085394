.headings-summary {
  width: 100%;
  padding: 20px 15px 10px;
}

.heading-summary-title {
  display: flex;
  align-items: center;
}

.title-text {
  padding: 0 10px;
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 15px;
  color: #384259;
}

.summary-list {
  padding: 10px 10px 5px 35px;
}
