@import "../_global-vars.css";

h2,
h3 {
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 50px;
  background-color: #FAFBFF;
  height: 95px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 15px;
  background-color: #FAFBFF;
  height: 55px;
}

.header-left {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.header-right {
  display: flex;
  align-items: center;
  
}

.header-info {
  display: flex;
  font-size: 13px;
  font-family: "Inter UI";
  font-weight: 600;
  line-height: 18px;
}

.header-info-name {
  color: #33383f;
}

.header-info-value {
  color: #748094;
  margin-left: 5px;
}

.header-credits-wrapper {
  width: 125px;
  margin: 0 20px;
}

.header-divider {
  background-color: var(--color-inactive-input);
  height: 30px;
  width: 1px;
}

.topic-area {
  padding: 0 20px;
  font-family: "Graphik";
  color: #364150;
}

.topic-area span {
  font-family: "Inter UI";
  color: #75819b;
  font-weight: 500;
}

.visits-area {
  padding: 0 20px;
  font-family: "Circular Std Book";
  color: #6777e6;
}

.visits-area span {
  font-family: "Inter UI";
  color: #75819b;
  font-weight: 500;
}

.brief-url-area {
  display: flex;
  width: 400px;
  padding: 0 50px;
}

.brief-url-area a {
  font-family: "Graphik";
  font-weight: normal;
  color: #75819b;
  font-size: 14px;
}

.url-brief {
  display: flex;
  align-items: center;
}

.url {
  display: inline-block;
  width: 300px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.external-icon {
  margin-left: 10px;
}
