@import '../_global-vars.css';

.custom-checkbox {
  display:flex;
  padding: 20px 0 0;
}

.checkbox-label {
  font-size: 14px;
  margin-left: 5px;
  color: var(--color-light-text);
}
