.skeleton-competition-card {
  display: flex;
  flex-direction: column;
  border-top: solid 1px #D7E1EA;
  padding: 20px 10px;
  box-sizing: border-box;
  width: 100%;
}

.skeleton-competition-card-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.skeleton-competition-card-circle {
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.skeleton-competition-bars {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skeleton-competition-bars-top {
  display: flex;
  justify-content: space-between;
}

.skeleton-competition-bars-bottom {
  display: flex;
  flex-direction: column;
}

.skeleton-competition-analyze {
  margin-top: 10px;
  padding-left: 78px;
}

.skeleton-competition-card .react-loading-skeleton {
  border-radius: 25px;
  line-height: 0;
}
