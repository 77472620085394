.projectsPerformanceDashboard {
  display:flex;
  width: 100%;
  min-height: 100%;
  background-color: #FAFBFF;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 80px;
  position: relative;
}

.projectsPerformanceListWrapper {
  margin: 0 30px 30px;
  padding: 50px 30px 30px;
  background-color: white;
  border-radius: 20px;
  /* min-width: 1495px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26); */
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.projectsPerformanceDashboard .mainList {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas:
    "head"
    "card";
}

.projectsPerformanceDashboard .listHead {
  display: grid;
  grid-area: head;
  color: #6f767e;
  padding: 15px 0;
  align-items: center;
  font-size: 13px;
  font-family: var(--default-font);
  font-weight: 600;
  /* grid-template-columns: 50px 150px 150px 150px 150px 150px 150px 150px 150px; */
  /* grid-template-columns: 50px 200px 150px 150px 150px 150px 150px 1fr 50px; */
  grid-template-columns: 50px 20fr 15fr 15fr 15fr 15fr 15fr 35fr 50px;
  grid-template-areas: "select select-title articles-title health-title score-title opportunity-title visits-title rank-title menu-items";
  /* grid-template-areas: "select select-title articles-title health-title score-title opportunity-title visits-title impression-title rank-title"; */
}

/* header areas */
.projectsPerformanceDashboard .selectCard {
  grid-area: select;
}

.projectsPerformanceDashboard .selectAllTitle {
  grid-area: select-title;
}

.projectsPerformanceDashboard .articlesTitle {
  grid-area: articles-title;
}

.projectsPerformanceDashboard .healthTitle {
  grid-area: health-title;
}

.projectsPerformanceDashboard .scoreTitle {
  grid-area: score-title;
}

.projectsPerformanceDashboard .opportunityTitle {
  grid-area: opportunity-title;
}

.projectsPerformanceDashboard .visitsTitle {
  grid-area: visits-title;
}

.projectsPerformanceDashboard .impressionsTitle {
  grid-area: impression-title;
}

.projectsPerformanceDashboard .rankTitle {
  grid-area: rank-title;
  display: flex;
  justify-content: flex-end;
}

.projectsPerformanceDashboard .writerTitle {
  grid-area: writer-title;
  display: flex;
  justify-content: flex-end;
}

/* card body areas */
.projectsPerformanceDashboard .performanceCard {
  display: grid;
  padding: 4px 0;
  color: rgb(117, 129, 155);
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 13px;
  grid-area: card;
  border-radius: 0px;
  border-top: solid 1px #f4f4f4;
  /* grid-template-columns: 50px 150px 150px 150px 150px 150px 150px 150px 150px 100px; */
  grid-template-columns: 50px 20fr 15fr 15fr 15fr 15fr 15fr 35fr 50px;
  grid-template-rows: 55px;
  grid-template-areas:
    /* "checkbox client-name articles-number health-info   score-info   opportunity-info  visits-info impression-info rank-info menu-items"; */
    "checkbox client-name articles-number health-info   score-info   opportunity-info  visits-info rank-info menu-items";
}

.projectsPerformanceDashboard .performanceCard:hover {
  background: #f4f4f4;
  border-radius: 8px;
  border-top: solid 1px transparent;
}

.projectsPerformanceDashboard .cardCheck {
  grid-area: checkbox;
  display: flex;
  align-items: center;
}

.projectsPerformanceDashboard .imageWrapper {
  width: 80px;
  height: 80px;
  margin: 0;
  padding: 0;
  background: #6f767e;
  overflow: hidden;
  border-radius: 10px;
}

.projectsPerformanceDashboard .clientName {
  grid-area: client-name;
  display: flex;
  align-items: center;
  /* font-size: 16px;
  font-weight: 600;
  color: #11142d;
  font-family: var(--default-font); */
}

.projectsPerformanceDashboard .articleInfo {
  grid-area: articles-number;
  display: flex;
  align-items: center;
  font-weight: 600;
  /* font-size: 16px;
  font-weight: 600;
  color: #11142d;
  font-family: var(--default-font); */
}

.projectsPerformanceDashboard .healthInfo {
  grid-area: health-info;
  display: flex;
  align-items: center;
}

.projectsPerformanceDashboard .circleWrapper {
  width: 40px;
  height: 40px;
  position: relative;
}

.projectsPerformanceDashboard .healthScore {
  position: absolute;
  transform: translateY(-28%);
  font-size: 12px;
  color: rgb(117, 129, 155);
  font-family: "Inter UI";
  font-weight: 600;
}

.projectsPerformanceDashboard .scoreInfo {
  grid-area: score-info;
  display: flex;
  align-items: center;
}

.projectsPerformanceDashboard .scoreValue {
  color: rgb(117, 129, 155);
  font-family: "Inter UI";
  font-weight: 600;
  font-size: 13px;
}

.projectsPerformanceDashboard .visitsInfoPerf {
  grid-area: visits-info;
  display: flex;
  align-items: center;
}

.projectsPerformanceDashboard .centerLabels {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.projectsPerformanceDashboard .centerLabelsOffsetPerf {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.projectsPerformanceDashboard .visitsTrend {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.projectsPerformanceDashboard .visitsTrendValue {
  margin-left: 4px;
  font-size: 12px;
  color: #83bf6e;
  font-weight: 900;
  font-family: var(--default-font);
}
.projectsPerformanceDashboard .infoTag {
  display: flex;
  justify-content: center;
  padding: 3px 5px;
  width: 60px;
  border-radius: 5px;
  color: rgb(117, 129, 155);
  font-family: "Inter UI";
  font-weight: 600;
  font-size: 13px;
}

.projectsPerformanceDashboard .statusTag {
  display: flex;
  justify-content: center;
  padding: 3px 5px;
  border-radius: 5px;
  color: rgb(117, 129, 155);
  font-family: "Inter UI";
  font-weight: 600;
  font-size: 13px;
}
.projectsPerformanceDashboard .impressionsInfo {
  grid-area: impression-info;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.projectsPerformanceDashboard .opportunityInfo {
  grid-area: opportunity-info;
  display: flex;
  align-items: center;
}

.projectsPerformanceDashboard .rankInfo {
  grid-area: rank-info;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.projectsPerformanceDashboard .menuItemsInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-area: menu-items;
}

.projectsPerformanceDashboard .menuIcons {
  display: flex;
  align-items: center;
}

.projectsPerformanceDashboard .iconWrapper {
  cursor: pointer;
  margin-left: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
