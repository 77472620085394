.dashboard-topbar-full {
  display: flex;
  flex: 1;
  margin-bottom: 20px;
  padding: 0 12px;
}

.title-tag-top-bar {
  display: block;
  width: 20px;
  height: 48px;
  border-radius: 4px;
  background-color: #6C5DD3;
  margin-right: 20px;
}

.topbar-children-content {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}