.managementDashboard {
  padding: 0 15px;
  /* min-width: 1495px; */
  height: 100%;
  margin: 0 auto;
}

.manag-mainList {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas:
    "head"
    "card"
}

.manag-listHead {
  display: grid;
  grid-area: head;
  color: #6F767E;
  padding: 15px 0;
  align-items: center;
  font-size: 13px;
  font-family: var(--default-font);
  font-weight: 600;
  /* grid-template-columns: 50px 80px 250px repeat(7, 1fr) 50px; */
  /* repeat(7, minmax(125px, 1fr) ) */
  /* grid-template-columns: 50px 80px 250px 50px 125px 100px 125px 130px 180px 180px 50px; */
  grid-template-columns: 50px 80px 30fr 20fr 20fr 20fr 20fr 20fr 20fr 25fr 10fr;
  grid-template-areas:
  "title-select title-type title-topic title-industry title-created title-deadline title-writer title-proof title-project title-status ."
}

.titleSelectCard {
  grid-area: title-select;
}

.titleType {
  grid-area: title-type;
}

.titleTopic {
  grid-area: title-topic;
}

.titleSimilarity {
  grid-area: title-similarity;
}

.titleWriter {
  grid-area: title-writer;
  display: flex;
  justify-content: center;
}

.titleProject {
  grid-area: title-project;
}

.titleCreated {
  grid-area: title-created;
  display: flex;
  justify-content: center;
}

.text-title-created {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}

.titleDeadline {
  grid-area: title-deadline;
}
.titleScore {
  grid-area: title-industry;
  display: flex;
  justify-content: center;
}
.titleProof {
  grid-area: title-proof;
  display: flex;
  justify-content: center;
}
.titleStatus {
  grid-area: title-status;
}

.managementCard {
  display: grid;
  align-items: center;
  color: #75819b;
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 13px;
  padding:7px 0;
  grid-area: card;
  border-radius: 0px;
  border-top: solid 1px #F4F4F4;
  
  /* grid-template-columns: 50px 80px 250px repeat(7, minmax(125px, 1fr) ) 50px; */
  /* grid-template-columns: 50px 80px 250px 50px 125px 100px 125px 130px 180px 180px 50px; */
  grid-template-columns: 50px 80px 30fr 20fr 20fr 20fr 20fr 20fr 20fr 25fr 10fr;
  grid-template-areas:
  "content-select content-type content-topic content-industry content-created content-deadline content-writer  content-proof content-project content-status ."
}

.contentCheck {
  grid-area: content-select;
}

.contentType {
  grid-area: content-type;
}

.contentTopic {
  grid-area: content-topic;
}

.contentWriter {
  grid-area: content-writer;
  display: flex;
  justify-content: center;
}

.contentProject {
  grid-area: content-project;
}

.contentCreated {
  grid-area: content-created;
  display: flex;
  justify-content: center;
}

.contentDeadline {
  grid-area: content-deadline;
}

.contentScore {
  grid-area: content-industry;
  display: flex;
  justify-content: center;
}

.contentProof {
  grid-area: content-proof;
  display: flex;
  justify-content: center;
}

.contentStatus {
  grid-area: content-status;
}

.managementCard:hover {
  background: #F4F4F4;
  border-radius: 8px;
  border-top: solid 1px transparent;
}

.contentMenu {
  display: flex;
  justify-content: flex-end;
}