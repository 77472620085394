.brief-form-wrapper {
  display: flex;
  width: 98%;
  min-height: 100%;
  padding: 0 10px;
  justify-content: flex-start;
  flex-direction: column;
}

.project-brief-content textarea {
  resize: none;
  margin-bottom: 40px;
}

.project-brief-content textarea::placeholder {
  color: #d9e1e9;
}

.brief-select-inputs {
  display: flex;
  margin-top: 34px;
}

.brief-select-inputs .react-select-container {
  margin-bottom: 40px;
}

.brief-left-column {
  width: 50%;
  margin-right: 20px;
  font-size: 14px;
}

.brief-right-column {
  width: 50%;
  margin-left: 20px;
  font-size: 14px;
  margin-top: 81px;
}

.brief-left-column textarea {
  margin-bottom: 40px;
}

.brief-right-column textarea {
  margin-bottom: 40px;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.project-brief-submit {
  width: 100%;
}

.confirmation-brief-submit-modal {
  background: white;
  padding: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
