@import "../_global-vars.css";

.reset-component {
  display: flex;
  align-items: center;

  padding: 80px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 15px;
  background: white;
}

.reset-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 70px 50px;
  width: 600px;
}

.reset-right-content {
  padding: 50px 100px 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.reset-app-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.reset-app-logo p {
  font-family: var(--default-font);
  color: var(--color-light-text);
  margin-top: 10px;
}

.reset-title-copy {
  text-align: center;
  font-size: 32px;
  color: #2e2e2e;
  font-family: "Graphik";
  font-weight: 900;
  margin-bottom: 18px;
}

.reset-subcopy {
  text-align: center;
  color: #2e2e2e;
  font-family: "Inter UI";
  font-size: 14px;
  line-height: 20px;
}

.copy-area h1 {
  color: var(--color-light-text);
  font-family: "GT Super Display";
}
