.multistep-register-component {
  display: flex;
  padding: 50px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 15px;
  background: white;
  width: 1000px;
}

.multistep-register-app-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -16px;
}

.multistep-register-left-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 300px;
  flex: 1;
  padding: 0 50px;
}

.multistep-register-divider {
  background-color: var(--color-inactive-input);
  height: 450px;
  width: 1px;
}

.multistep-register-right-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 300px;
  flex: 1;
  padding: 0 50px;
  border-left: solid 1px var(--color-inactive-input);
}

.register-desc {
  margin-bottom: 10px;
  font-size: 14px;
  color: #6f767e;
}

.multistep-register-form {
  width: 100%;
}

.multistep-register-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.row-input {
  display: flex;
  flex: 1;
}

.MuiStepper-root {
  padding: 0 !important;
}
