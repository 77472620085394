.description-editor-wrapper .ProseMirror {
  margin: 0 auto;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  height: calc(100vh - 430px);
  width: 100%;
  padding: 20px;
  overflow: scroll;
}

.category-editor .editor__header {
  display: flex;
  padding: 14px 20px;
  align-items: center;
}
