.text-editor-footer {
  padding: 10px 90px;
  font-size: 12px;
  font-family: "Inter UI";
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
}

.text-editor-footer-info {
  display: flex;
  align-items: center;
}

.footer-text {
  margin: 0 15px;
}

.text-editor-footer-info span {
  background: #ececec;
  padding: 2px 5px;
  border-radius: 5px;
  width: 45px;
  color: #72727b;
  font-weight: bold;
  margin-right: 20px;
  text-align: center;
}

.editor-footer-actions {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.footer-icon-wrapper {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin: 0 5px;
}
