.title-with-tag {
  display: flex;
  align-items: center;
}

.tag {
  display: block;
  width: 20px;
  height: 48px;
  border-radius: 4px;
  background-color: #dadada;
}

.title {
  margin-left: 16px;
  font-size: 26px;
  color: #1a1d1f;
  font-weight: bold;
  font-family: var(--default-font);
}
