.category-inputs {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.category-input-titles {
  width: 45%;
}
.meta-title-wrapper {
  position: relative;
}

.meta-title-info {
  position: absolute;
  top: 0;
  left: 85px;
}

.meta-desc-wrapper {
  position: relative;
  width: 45%;
}

.meta-desc-info {
  position: absolute;
  top: 16px;
  left: 134px;
}

.h1-tag-wrapper {
  position: relative;
}

.h1-tag-info {
  position: absolute;
  top: 1px;
  left: 54px;
}

.h1-tag-copy,
.meta-desc-copy,
.meta-title-copy {
  position: absolute;
  cursor: pointer;
}

.meta-title-copy {
  top: -2px;
  left: 18px;
}

.h1-tag-copy {
  top: -1px;
  left: 72px;
}

.meta-desc-copy {
  top: 14px;
  left: 152px;
}
