.members-component {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.members-left {
  flex: 1;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  padding-right: 25px;
}

.members-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-left: 35px;
  min-width: 600px;
}

.vertical-divider {
  background-color: #f4f4f4;
  height: 504px;
  width: 1px;
}

.account-data {
  display: flex;
  margin-top: 50px;
}

.info-content {
  margin-bottom: 20px;
  padding-right: 50px;
}

.info-title {
  font-size: 16px;
  color: #1a1d1f;
  font-weight: 600;
  font-family: var(--default-font);
}

.info-value {
  font-size: 14px;
  color: #6f767e;
  font-weight: 500;
  font-family: var(--default-font);
  margin: 7px 0;
}

.members-list-head {
  display: grid;
  color: #6f767e;
  padding: 15px 0;
  align-items: center;
  font-size: 13px;
  font-family: var(--default-font);
  font-weight: 600;
  grid-template-columns: 2fr 1fr 1fr 1fr 50px;
}

.members-list-row {
  display: grid;
  color: #6f767e;
  border-top: solid 1px #f4f4f4;
  align-items: center;
  font-size: 13px;
  font-family: var(--default-font);
  font-weight: 600;
  grid-template-columns: 2fr 1fr 1fr 1fr 50px;
}

.members-pill {
  background: rgba(228, 228, 228, 0.25);
  padding: 5px 10px;
  border-radius: 62px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  width: 43px;
  height: 32px;
  margin-left: 5px;
}

.members-table {
  border-bottom: solid 1px #f4f4f4;
  margin-right: 10px;
}

.invoice-summary {
  color: #6f767e;
  font-size: 13px;
  font-family: var(--default-font);
  font-weight: 600;
  margin-top: 15px;
}

.invoice-status {
  display: inline-flex;
  background: #f4f4f4;
  align-items: center;
  border-radius: 7px;
  padding: 0 7px;
  min-height: 24px;
}

.members-right-side-form {
  display: flex;
  flex-direction: column;
}

.members-register-desc {
  margin-bottom: 10px;
  font-size: 14px;
  color: #6f767e;
}
