.ratingComponent {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-name {
  font-weight: bold;
  font-size: 15px;
  color: var(--color-dark-text);
  margin: 10px 0;
}

.rating-submit-btn-wrapper {
  margin: 0 10px;
}

.rating-submit-btn-wrapper .button--default {
  border-radius: 10px;
  padding: 12px 30px;
}

.rating-submit-btn-wrapper .button--passive {
  border: solid 1px var(--color-active-input);
}

.rating-submit-btn-wrapper .button:disabled {
  background: rgb(238, 238, 238);
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.rating-footnote {
  margin-bottom: 10px;
  font-size: 12px;
  margin-top: 10px;
  color: var(--color-light-text);
}
