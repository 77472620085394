.display-date-wrapper {
  font-weight: bold;
  display: block;
  padding: 2px 8px;
  cursor: pointer;
  border: solid 1px var(--color-inactive-input);
  border-radius: 5px;
  background: white;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-only-display {
  padding: 16px;
  pointer-events: fill;
}

/* deadline passed  */
.display-date-wrapper-deadline-passed {
  color: #EE7D57;
  /* color: #a97060; */
  font-weight: bold;
  display: block;
  padding: 2px 8px;
  cursor: pointer;
  border: solid 1px #EE7D57;
  /* border: solid 1px #a97060; */
  border-radius: 5px;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* in time  */
.display-date-wrapper-deadline-in-time {
  background: white;
  color: #384359;
  font-weight: bold;
  display: block;
  padding: 2px 8px;
  cursor: pointer;
  border: solid 1px var(--color-inactive-input);
  border-radius: 5px;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* deadline close  */
.display-date-wrapper-deadline-close {
  color: #e898a2;
  font-weight: bold;
  display: block;
  padding: 2px 8px;
  cursor: pointer;
  border: solid 1px #e898a2;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
