.briefDashboard {
  padding: 0 15px;
  /* min-width: 1495px; */
  height: 100%;
  margin: 0 auto;
}

.brief-mainList {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas:
    "head"
    "card";
}

.brief-listHead {
  display: grid;
  grid-area: head;
  color: #6f767e;
  padding: 15px 0;
  align-items: center;
  font-size: 13px;
  font-family: var(--default-font);
  font-weight: 600;
  /* grid-template-columns: 50px 450px 1fr repeat(3, minmax(125px, 1fr)) 100px; */
  grid-template-columns: 50px 45fr 20fr 20fr 20fr 100px;
  grid-template-areas: "title-select title-project title-industry1 title-industry2 title-completion title-action";
}

.titleSelectCard {
  grid-area: title-select;
}

.titleProject {
  grid-area: title-project;
}

.titleUrl {
  grid-area: title-url;
}

.titleIndustry1 {
  grid-area: title-industry1;
}

.titleIndustry2 {
  grid-area: title-industry2;
}

.titleCompletion {
  grid-area: title-completion;
}

.titleAction {
  grid-area: title-action;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.projectBriefCard {
  display: grid;
  align-items: center;
  color: #75819b;
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 13px;
  padding: 7px 0;
  grid-area: card;
  border-radius: 0px;
  border-top: solid 1px #f4f4f4;
  /* grid-template-columns: 50px 450px 1fr repeat(3, minmax(125px, 1fr)) 100px; */
  grid-template-columns: 50px 45fr 20fr 20fr 20fr 100px;
  grid-template-areas: "content-select content-project content-industry1 content-industry2 content-completion content-action";
}

.contentProject {
  font-family: "Inter UI";
  font-weight: 500;
  cursor: pointer;
}
.contentProject:hover {
  color: #6870f6;
}

.contentCompletion {
  display: flex;
  align-items: center;
  max-width: 140px;
}

.contentAction {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.projectBriefCard:hover {
  background: #f4f4f4;
  border-radius: 8px;
  border-top: solid 1px transparent;
}

.team-pill {
  background: rgba(228, 228, 228, 0.25);
  padding: 5px 10px;
  border-radius: 62px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  width: 43px;
  height: 32px;
  margin-left: 20px;
}
