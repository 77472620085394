.header-buttons {
  display: flex;
  /* width: 375px; */
  margin: 0 20px;
  align-items: center;
}

.header-buttons img {
  cursor: pointer;
}

.header-icons {
  display: flex;
  width: 60px;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
}

.notification-modal {
  background: #384359;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.notification-message {
  color: #d9e1e9;
  font-size: 16px;
}

.user-action-button {
  padding: 8px 20px;
  background: white;
  border: solid 2px #6870f6;
  border-radius: 8px;
  color: #6870f6;
  cursor: pointer;
  font-weight: 600;
}

.save-draft-button {
  padding: 8px 20px;
  background: white;
  border: solid 2px #6870f6;
  border-radius: 8px;
  color: #6870f6;
  cursor: pointer;
  margin-right: 20px;
}

.user-action-button:hover {
  background: #6870f6;
  color: white;
}

.user-action-button:focus {
  outline: none;
}

.submit-modal {
  background: white;
  padding: 0 50px 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.submit-modal-control {
  display: flex;
  align-self: flex-end;
}

.close-rating-button {
  display: block;
  padding: 6px 9px;
  border-radius: 8px;
  border: solid 1px var(--color-inactive-input);
  cursor: pointer;
  color: var(--color-light-text);
  height: 32px;
  margin-right: -34px;
  margin-top: 10px;
}

.submit-modal-title {
  color: #384259;
  font-family: "Graphik";
  font-size: 22px;
  line-height: 50px;
}

.read-only-info {
  display: flex;
  align-items: center;
}

.info-text {
  font-family: "Inter UI";
  color: #75819b;
  font-weight: 500;
  margin-right: 5px;
}
