.custom-tag,
.custom-tag-used {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter UI";
  font-weight: 500;
  color: #384259;
  padding: 25px 10px 25px 25px;
  background-color: #f6f7fb;
  margin: 0 3px 3px;
  position: relative;
}

.custom-tag-used {
  color: #75819b;
  background-color: #f9f9fd;
}

.custom-tag-used .keyword {
  text-decoration: line-through;
}

.custom-tag-used .used-in-title,
.custom-tag-used .used-in-headings,
.custom-tag-used .used-in-paragraph {
  text-decoration: line-through;
}

.custom-tag-dot,
.custom-tag-dot-used {
  min-height: 15px;
  min-width: 15px;
  margin-right: 15px;
  background-color: #df94a1;
  border-radius: 50%;
  display: inline-block;
}

.custom-tag-dot-partially-paragraph,
.custom-tag-dot-partially-heading,
.custom-tag-dot-partially-title {
  background-color: #eacd7f;
}

.custom-tag-dot-used {
  background-color: #afe9cb;
}

.dot-and-label {
  display: flex;
  align-items: center;
  max-width: 400px;
}

.right-side-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 135px;
  margin-right: 45px;
}

.used-in-headings,
.used-in-paragraph,
.used-in-title {
  display: flex;
  align-items: center;
  justify-self: end;
  padding-left: 15px;
}

.used-in {
  font-family: "Inter UI";
  font-weight: normal;
  color: #384359;
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 1px;
}

.custom-tag-partially-title .used-in-title {
  text-decoration: line-through;
}

.custom-tag-partially-heading .used-in-headings {
  text-decoration: line-through;
}

.custom-tag-partially-paragraph .used-in-paragraph {
  text-decoration: line-through;
}

.remove-tag {
  position: absolute;
  top: 50% - 7px;
  right: 8px;
  font-size: 11px;
  padding: 1px 5px;
  cursor: pointer;
}

.complete-tag {
  position: absolute;
  top: 50% - 7px;
  right: 30px;
  font-size: 11px;
  padding: 1px 5px;
  cursor: pointer;
}

.keyword-usage {
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
  padding-top: 5px;
}

.keyword-search-volume {
  font-family: "Inter UI";
  font-weight: 500;
  color: #75819b;
  font-size: 14px;
  padding-top: 5px;
}

.monthly-searches {
  font-family: "Inter UI";
  font-weight: normal;
  color: #384359;
  font-size: 12px;
  margin-bottom: 1px;
}

.interactive-label {
  font-family: "Inter UI";
  font-weight: normal;
  padding: 1px 5px;
  border-radius: 8px;
}

.used-keyword {
  color: #4daa7a;
  font-size: 10px;
  background: #ebfff5;
  border: solid 1px #4daa7a;
}
.removed-keyword {
  color: #d78291;
  font-size: 10px;
  background: #ffeaee;
  border: solid 1px #d78291;
}
