.plagiarism-subtitle {
  color: #a2a2a2;
  font-family: "Graphik";
  font-size: 14px;
}

.plagiarism-mid-wrapper {
  display: flex;
  width: 90%;
  margin-top: 30px;
  height: 175px;
}

.plagiarism-left {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.circle-box {
  width: 100px;
  height: 100px;
}

.circle-info {
  margin-left: 20px;
}

.plag-info,
.unique-info {
  display: flex;
  align-items: center;
}

.plag-text,
.unique-text {
  width: 100px;
  font-size: 16px;
  font-weight: 700;
}

.plag-score,
.unique-score {
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
  width: 75px;
}

.plag-text,
.plag-score {
  color: #e898a2;
}

.unique-text,
.unique-score {
  color: #ade8c9;
}

.plag-info {
  margin-bottom: 10px;
}

.plagiarism-right {
  display: flex;
  flex-grow: 1;
  margin-left: 20px;
  background: #fbfbfb;
  width: 50%;
}

.plagiarism-sources {
  display: flex;
  padding: 10px;
  flex-grow: 1;
  background: #fbfbfb;
}

.vertical-line {
  display: block;
  width: 5px;
  height: 100%;
  background: #f4f5f6;
}

.submit-modal-controls {
  margin-top: 10px;
  margin-bottom: 20px;
}
