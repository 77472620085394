.brief-titles {
  font-family: "Graphik";
  font-weight: normal;
  color: #384259;
  font-size: 14px;
  margin-bottom: 8px;
}

.links-collection {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
  color: #adb1b8;
  padding-bottom: 10px;
}

.links-collection a {
  display: flex;
  justify-content: flex-start;
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
  color: #adb1b8;
  margin-bottom: 5px;
}

.links-collection-stats {
  padding: 0 20px;
}

.links-collection :last-child,
.links-collection :first-child {
  border: none;
}

.links-collection :first-child {
  text-decoration: underline;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.links-collection :first-child img {
  padding-left: 3px;
}
