.results-wrapper {
  display: flex;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 16px;
  max-width: 446px;
  gap: 5px;
  margin-top: 20px;
}

.summary-text {
  color: #75819b;
  font-size: 12px;
  font-family: "Inter UI";
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  text-align: center;
}

.summary-text span {
  font-size: 24px;
}

.btnbottom {
  display: flex;
  justify-content: space-around;
}
.btnbottom button {
  width: 45%;
  outline: none;
  border: none;
  height: 25px;
  cursor: pointer;
  border-radius: 4px;
}

.summary-info-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #75819b;
  background-color: #f8f8f8;
  padding: 10px;
  border: solid 1px white;
  border-radius: 6px;
  min-width: 60px;
  font-size: 12px;
}

.summary-info-box svg {
  width: 20px;
  height: 20px;
  fill: #75819b;
  margin-bottom: 10px;
}
