.topic-clusters {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 15px 20px 15px 30px;
}
.clusters-keyword {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #1b1d1f;
}

.cluster-values {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 900;
  font-size: 18px;
}

.clusters-keyword-number {
  display: flex;
  justify-content: flex-end;
  color: #75819b;
}

.cluster-desc {
  font-family: "Inter UI";
  font-size: 10px;
  font-weight: 900;
  /* color: #76819b; */
  color: #75819b;
}

.clusters-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.toggle-extra-keywords {
  cursor: pointer;
  background: white;
  border: solid 2px #6870f6;
  border-radius: 8px;
  color: #6870f6;
  padding: 10px;
  min-width: 127px;
  font-weight: 700;
  text-align: center;
}
.toggle-extra-keywords:hover {
  background: #6870f6;
  color: white;
}

.toggled-keywords {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  margin-top: 10px;
  margin-bottom: 50px;
  gap: 10px;
}

.extra-key-tag {
  text-align: center;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-radius: 25px;
}

.key-desc {
  font-size: 13px;
  font-weight: 700;
  color: #606068;
  margin-bottom: 1px;
}

.key-search {
  font-size: 9px;
  font-weight: 700;
  color: #75819b;
}
