.headings-group {
  margin-top: 20px;
}

.headings-group:first-child {
  margin-top: 10px;
}

.headings-list {
  display: flex;
}

.headings-title {
  color: #6a71f6;
  font-family: "Graphik";
  font-weight: 500;
  font-size: 13px;
  padding-left: 5px;
  margin-bottom: 10px;
}

.heading-text {
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 5px;
  color: #a2a7b0;
}
