@import "../_global-vars.css";

.keywords-toggle {
  display: flex;
  justify-content: space-between;
  padding: 0 25px 25px 25px;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 3px;
  bottom: 0;
  background-color: #66ca95;
  border: solid 1px #55b682;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider {
  background-color: #66ca95;
}

input:checked + .slider::before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 35px;
}

.slider.round::before {
  border-radius: 50%;
}

.switch-label {
  font-family: "Graphik";
  font-weight: normal;
  color: #384259;
  padding-right: 5px;
}

.switch-label-active {
  color: #66ca95;
}

.high-relevance,
.low-relevance {
  display: flex;
  align-items: center;
}
