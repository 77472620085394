.dashboard-popup-wrapper {
  background: white;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
