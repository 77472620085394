.projects-dashboard-topbar {
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 20px;
  font-size: 14px;
}

.projects-dashboard-topbar .react-select__control {
  background: #f8f9fb;
  border: none !important;
}

.projects-dashboard-topbar .react-select__menu {
  padding: 5px 10px;
  border: none !important;
  font-size: 13px;
  border-radius: 10px;
  outline: none !important;
}

.projects-dashboard-topbar .react-select__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: white;
  color: #384359;
}

.projects-dashboard-topbar .react-select__option:hover {
  background: #f2f0f9;
  border-radius: 6px;
}

.editBriefActionBtn {
  background: #f8f9fb;
  border: none;
  padding: 3px 15px;
  color: #75819b;
  border-radius: 5px;
  cursor: pointer;
}

.editBriefActionBtn:hover {
  background: #dce6fa;
}

.project-column {
  color: #75819b;
  font-family: "Inter UI";
  font-weight: 500;
}

.url-column {
  color: #75819b;
  font-family: "Inter UI";
  font-weight: 500;
}

.project-column:hover {
  color: #6870f6;
}

.briefDashboard .PrivateTabIndicator-colorSecondary-14 {
  background-color: #6c5dd3 !important;
  height: 3px !important;
}

.briefDashboard .MuiTabs-indicator {
  background-color: #6c5dd3 !important;
  height: 3px !important;
}

.briefDashboard .MuiTabs-fixed {
  border-bottom: solid 1px #f4f4f4;
}

.briefDashboard .MuiTab-wrapper {
  font-size: 14px !important;
  /* color: #6A7383 !important; */
  font-weight: 600 !important;
  font-family: var(--default-font) !important;
  text-transform: capitalize !important;
  opacity: 1 !important;
}

.MuiTab-textColorInherit.Mui-selected {
  color: #6a5ecc !important;
  opacity: 1 !important;
}

.MuiTab-textColorInherit {
  color: #6a7383 !important;
  opacity: 1 !important;
}

.briefDashboard .MuiBox-root {
  padding: 0 !important;
}

.topbar-right {
  display: flex;
}
