.search-keywords {
  margin-bottom: 25px;
  padding: 0 25px;
}

.search-keywords .form-control {
  margin: 0;
}

.search-keywords label {
  margin: 0;
}