.competition-box {
  background-color: #f8f8f8;
  padding: 14px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
}

.competition-box-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.competition-box-image img {
  border-radius: 50%;
}

.competition-domain {
  font-family: "Inter UI";
  font-size: 12px;
  color: #75819b;
  font-weight: 700;
  margin-top: 10px;
}

.competition-detail-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 8px 20px;
}

.detail-box-value {
  font-family: "Inter UI";
  font-size: 18px;
  font-weight: 900;
}

.detail-box-desc {
  font-family: "Inter UI";
  font-size: 10px;
  color: #75819b;
  font-weight: 900;
}
