.plagiarism-screen {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  min-width: 720px;
  min-height: 200px;
  padding: 30px 0;
}

.plagiarism-progress {
  width: 70%;
}

.plagiarism-progress-info {
  color: #a2a2a2;
  font-family: "Graphik";
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
}

.plagiarism-progress .MuiLinearProgress-colorPrimary {
  background: var(--color-inactive-input);
}

.plagiarism-progress .MuiLinearProgress-barColorPrimary {
  background: var(--color-active-input);
}

.plagiarism-warning {
  color: var(--color-error-input);
}

.plagiarism-source-title {
  color: #384259;
  font-family: "Graphik";
  font-size: 18px;
  margin: 13px 0;
}
