.homepage {
  display: flex;
  width: 100%;
  height: 100%;
}

.homepage-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 85px;
  width: calc(100% - 85px);
}

.homepage-content {
  display: flex;
  width: 95%;
  justify-content: space-between;
  position: fixed;
  top: 100px;
}

.homepage-editor {
  flex: 1;
}

.search-topic__modal-content {
  padding: 0;
}
