.members-select-container {
  width: 400px;
}

.members-select__menu {
  padding: 5px 10px;
  border: none !important;
  border-radius: 10px;
  outline: none !important;
}

.members-select__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: white !important;
  color: #384359;
}

.members-select__option:hover {
  background: #f2f0f9 !important;
  border-radius: 6px;
}

.members-select__single-value {
  color: #75819b !important;
  font-family: "Inter UI";
  font-weight: 500;
}

.members-select__control {
  min-height: 35px !important;
  border: solid 1px var(--color-inactive-input) !important;
  border-radius: 7px !important;
}

.members-select__control--is-focused {
  border: solid 1px var(--color-active-input) !important;
  box-shadow: none !important;
}

.members-select__multi-value__remove:hover {
  background-color: var(--color-active-input) !important;
  color: white !important;
}
