@import "../_global-vars.css";

.login-component {
  display: flex;
  align-items: center;
  /* width: 1200px; */
  padding: 80px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 15px;
  background: white;
}

.login-app-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.login-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 100px 50px;
  /* max-width: 600px; */
  width: 600px;
}

.login-divider {
  background-color: var(--color-inactive-input);
  height: 450px;
  width: 1px;
}

.login-right-content {
  padding: 70px 100px 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.login-copy-area {
  color: #2e2e2e;
  text-align: center;
  font-family: "Graphik";
  font-size: 32px;
  line-height: var(--fancy-font-line-height);
  font-weight: 900;
  margin-top: 15px;
}

.login-subcopy {
  text-align: center;
  color: #2e2e2e;
  font-family: "Inter UI";
  font-size: 14px;
  line-height: 20px;
}
