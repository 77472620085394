.searchResultsDashboardList {
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: #fafbff;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 90px;
  padding-bottom: 80px;
  position: relative;
}

.results-content {
  margin: 0 30px 30px;
  padding: 40px 40px 30px;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.topic-research-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.results-competition-wrapper {
  display: grid;
  max-width: 1299px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin: 60px 0 120px 0;
}

.topic-serp-table-wrapper {
  margin: 40px 0;
}
