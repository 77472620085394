.steps-wrapper {
  padding-bottom: 10px;
}

.ai-sidebar {
  padding: 20px;
}

.step {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 10px 0;
}

.step-desc {
  font-family: "Inter UI";
  font-weight: 500;
  color: #b5b5c3;
  font-size: 13px;
  margin: 10px 0 20px 0;
}

.step-label {
  font-size: 14px;
  font-weight: 500;
}

.step-bullet {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3f51b5;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  color: white;
}

.step-content {
  padding-left: 20px;
  margin-left: 12px;
  border-left: 1px solid #bdbdbd;
}
