.image-input-url > input {
  border: 1px solid var(--color-inactive-input);
  border-radius: 7px;
  background: white;
  padding: 8px 10px;
  color: var(--color-light-text);
  margin-right: 15px;
}

.insert-image-btn {
  padding: 8px 20px;
  background: #6870f6;
  border: solid 2px #6870f6;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.image-opener {
  margin-top: 60px;
} 