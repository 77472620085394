.accordion-header-details {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.accordion-title {
  color: #6f767e;
  font-size: 14px;
  font-family: var(--default-font);
  font-weight: 600;
}

.accordion-title-active {
  color: #1a1d1f;
  font-size: 14px;
  font-family: var(--default-font);
  font-weight: 600;
}

.accordion-element {
  margin-bottom: 10px;
}

.remove-current-filter {
  color: "#ec5032";
  width: 18px;
  margin-left: 7px;
  display: "flex";
  align-items: "center";
}

.search-filter-heading {
  padding-top: 20px;
  padding-bottom: 40px;
}

.MuiAccordion-rounded:last-child {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.MuiAccordion-rounded:first-child {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.MuiAccordion-rounded {
  box-shadow: none !important;
}
