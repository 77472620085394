.user-credits-bar {
  background: #F0F1F7;
  width: 100%;
}

.user-credits-info {
  color: #748094;
  font-size: 13px;
  font-family: "Inter UI";
  font-weight: 600;
  line-height: 20px;
}