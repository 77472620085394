.rating-info {
  display: flex;
  align-items: center;
}

.user-data {
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 20px;
}

.rating-username {
  font-size: 14px;
  color: #33383f;
  font-family: "Inter UI";
  font-weight: 600;
}

.rating-stars {
  display: flex;
}

.user-menu {
  margin-top: 45px;
}
