@import "../_global-vars.css";

.form-error-message {
  font-family: "Inter UI";
  font-size: 12px;
  color: var(--color-error-input);
}

.form-success-message {
  font-family: "Inter UI";
  font-size: 14px;
  color: var(--color-success-input);
}
