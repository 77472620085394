.tag-select-component {
  position: relative;
}

.tag-selected {
  background: rgba(228, 228, 228, .25);
  padding: 5px 10px;
  border-radius: 62px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #5f75ee;
  font-family: "Inter UI";
  font-weight: 700;
  position: relative;
  width: 48px;
  height: 32px;
}

.dropdown-closer {
  display: block;
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 80;
  top: 0;
  left: 0;
  overflow-y: hidden;
}

.tag-list {
  background: white;
  position: absolute;
  z-index: 99;
  width: 210px;
  height: 300px;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 1px #F4F4F4;
  color: #384359;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.tag-list-item {
  cursor: pointer;
  padding: 5px 10px;
  margin: 2px 0;
  background: none;
  color: #384359;
}

.tag-list-item:hover {
  background: #f2f0f9;
  border-radius: 6px;
  color: #384359;
}

.selected-rating {
  background: #6A5ECC;
  color: white;
  border-radius: 50px;
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  right: 0px;
  top: -7px;
  padding: 1px 4px;
}