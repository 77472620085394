.results-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 5px 10px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.08);
}

.results-tooltip p {
  padding: 0 !important;
  margin: 0 !important;
}

.tooltip-label {
  color: #7a8797 !important;
  font-size: 12px !important;
  font-family: "Inter UI" !important;
  font-weight: 500 !important;
}

.tooltip-value {
  color: #3a4357 !important;
  font-size: 16px !important;
  font-family: "Circular Std Book" !important;
  font-weight: 500 !important;
}

.secondary-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #262b30;
  padding: 5px 10px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.08);
}

.secondary-tooltip p {
  padding: 0 !important;
  margin: 0 !important;
  color: white !important;
}

.secondary-tooltip-label {
  color: white !important;
  font-size: 12px !important;
  font-family: "Inter UI" !important;
  font-weight: 500 !important;
}

.secondary-tooltip-value {
  color: white !important;
  font-size: 14px !important;
  font-family: "Inter UI" !important;
  font-weight: 500 !important;
}

.info {
  display: flex;
  align-items: center;
}

.square {
  display: block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background: #b5e4ca;
}
