.performance-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 12px;
}

/* .performance-header-management {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 12px;
} */

.performance-left-side {
  display: flex;
  align-items: center;
}

.performance-right-side {
  display: flex;
  align-items: center;
}

.performance-action-icon {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin: 0 5px;
  cursor: pointer;
  /* width: 16px;
  height: 16px; */
  /* background: red; */
}

.performancebar-title {
  display: flex;
  align-items: center;
}

.title-tag {
  display: block;
  width: 20px;
  height: 48px;
  border-radius: 4px;
  background-color: #6c5dd3;
}

.perf-title {
  margin-left: 16px;
  font-size: 20px;
  color: #1a1d1f;
  font-weight: 600;
  font-family: var(--default-font);
}

.interval-select {
  /* margin-right: 18px; */
  display: flex;
  align-items: center;
}

.search-console-btn {
  margin-right: 15px;
}

/* dropdown menu stuff  */

.header-list-bar,
.header-search-bar {
  font-size: 14px;
  margin-left: 30px;
}

.header-list-bar .dropdown-menu,
.header-list-bar .dropdown-menu-single {
  width: 150px;
  margin-right: 30px;
}

.header-list-bar .react-select__control,
.header-search-bar .react-select__control {
  background: #f8f9fb;
  border: none !important;
}

.header-list-bar .react-select__menu {
  padding: 5px 10px;
  border: none !important;
  font-size: 13px;
  border-radius: 10px;
  outline: none !important;
}

.dropdown-menu .react-select__menu,
.dropdown-menu-single .react-select__menu {
  width: 210px;
}

.header-list-bar .react-select__menu-list {
  /* background: yellow; */
  /* border-radius: 20px; */
}

.header-list-bar .react-select__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: white;
  color: #384359;
}

.dropdown-menu .react-select__option:last-child {
  border-top: solid 1px #f2f0f9;
  color: #d20000;
}

.dropdown-menu-single .react-select__option:last-child {
  color: #d20000;
}

.header-list-bar .react-select__option:hover {
  background: #f2f0f9;
  border-radius: 6px;
}

.header-search-bar {
  width: 200px;
  margin-right: 20px;
}
