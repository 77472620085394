.head-topic-filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-search-topic {
  width: 100%;
}

.input-search-topic .form-control {
  margin: 0;
}
