.rename-topic-form {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.rename-topic .form-control {
  margin: 0;
}

.published-date {
  display: flex;
  justify-self: flex-start;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 0.3rem;
  font-size: 15px;
  color: var(--color-dark-text);
}

.published-label {
  display: flex;
  justify-content: flex-start;
}

.published-cal {
  display: flex;
  align-items: flex-end;
}