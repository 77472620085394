@import "../_global-vars.css";

.search-component {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.search-topic {
  width: 500px;
  padding: 10px;
  margin: 50px 0 0 110px;
}

.search-topic h2 {
  font-size: 43px;
  line-height: 50px;
  font-family: var(--fancy-font);
  color: var(--color-dark-text);
  font-weight: normal;
  margin-bottom: 40px;
}

.search-hints {
  margin-top: 40px;
}

.search-hints p {
  color: #b5b5c3;
  font-size: 13px;
  font-family: var(--default-font);
  margin: 10px 0;
}

.search-topic-form button {
  margin-top: 25px;
}

.search-topic-form .required__control {
  min-height: 35px !important;
  border: solid 1px var(--color-inactive-input) !important;
  border-radius: 7px !important;
}

.search-topic-form .required__control--is-focused {
  border: solid 1px var(--color-active-input) !important;
  box-shadow: none !important;
}

.search-topic-form .required__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: white;
  color: #384359;
}

.search-topic-form .required__option:hover {
  background: #f2f0f9;
  border-radius: 6px;
}

.search-topic-form .required__menu {
  padding: 5px 10px;
  border: none !important;
  border-radius: 10px;
  outline: none !important;
}

.search-topic-form .required__placeholder {
  color: var(--color-error-input);
  font-size: 15px;
}

.search-topic-form .required__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: white;
  color: #384359;
}

.search-topic-form .required__option:hover {
  background: #f2f0f9;
  border-radius: 6px;
}

.country-option-select {
  display: flex;
  align-items: center;
}

.country-option-icon {
  margin-top: 3px;
  margin-right: 10px;
}

.popup-title-search {
  font-size: 38px;
  font-family: 'Inter UI';
  color: var(--color-dark-text);
  font-weight: 900;
  margin-bottom: 20px;
}