.topic-name {
  color: #75819b;
  font-family: "Inter UI";
  font-weight: 500;
  cursor: pointer;
}

.topic-name:hover {
  color: #6870f6;
}
