@import '../_global-vars.css';

.login {
  display: flex;
  flex-direction: column;
}

.login-form {
  list-style: none;
  width: 400px;
}

.forgot-pass {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}

.sign-up-link {
  font-size: 14px;
  color: var(--color-light-text);
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.sign-up-link a {
  margin-left: 5px;
}
