.tabs-menu-header {
  background-color: #fafafa;
  /* border-bottom: solid 1px #ededed; */
}
.tabs-menu {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  width: 100%;
}

.tabs-menu li {
  width: 100%;
  font-size: 14px;
  font-family: "Graphik";
  color: #364150;
  font-weight: 500;
  list-style: none;
  text-align: center;
  background-color: #fafafa;
  padding: 18px 5px;
  border-right: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
  cursor: pointer;
}

.tabs-menu li.active {
  background-color: white;
  border-bottom: none;
}

.menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-icon .guides {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ebf4fd;
}

.guides img {
  margin-bottom: 2px;
  margin-right: 2px;
}

.guides svg {
  fill: #1a1d1f;
  height: 14px;
  width: 14px;
}

.menu-icon .competition {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff4de;
}

.menu-icon .feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f5edf1;
}

.menu-icon .brief {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ece5fd;
}

.brief img {
  margin-bottom: 2px;
}

.menu-icon p {
  padding-left: 5px;
}

.beta-flag {
  position: absolute;
  font-size: 7px;
  font-weight: 700;
  top: 8px;
  right: 8px;
}
