.bars-wrapper {
  display: flex;
  align-items: flex-end;
  height: 80px;
}

.single-bar {
  display: block;
  width: 30px;
  margin-right: 10px;
}