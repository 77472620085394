.custom-google-btn-style {
  border-radius: 10px !important;
  max-height: 38px !important;
  box-shadow: none !important;
  border: solid 2px #EFEFEF !important;
}

.custom-google-btn-style:hover {
  border: solid 2px  #dbdbdb !important;
}

.custom-google-btn-style  > div {
  padding: 2px 10px !important;
}
