.step3-inputs-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.email-input-step3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}
