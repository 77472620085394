.range-dates-input {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.date-picker-start,
.date-picker-end {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding-left: 2px;
}

.date-range-text {
  color: #6f767e;
  font-size: 14px;
  font-family: var(--default-font);
  font-weight: 600;
}

.MuiAccordionDetails-root {
  padding: 8px 1px 16px !important;
}
