.loader-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 150px 0;
}

.loader-animation p {
  color: var(--color-dark-text);
}

.loading-text {
  font-weight: 900;
  font-family: 'Inter UI';
  font-size: 18px;
}