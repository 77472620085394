@import "../_global-vars.css";

.topic-result {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  background: white;
  border-radius: 20px;
}

.topic-result-data {
  width: 530px;
  padding: 0 10px;
  margin-left: 60px;
}

.topic-result-data h2 {
  font-family: 'Graphik';
  font-size: 36px;
  line-height: 50px;
  color: #384359;

}

.topic-seached {
  font-family: 'Inter UI';
  font-weight: 500;
  font-size: 24px;
  color: #7a8797;

}

.topic-numbers {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 20px 0 30px;
}

.numbers-info {
  display: flex;
  align-items: center;
  font-family: 'Inter UI';
  font-weight: 500;
  font-size: 12px;
  color: #7a8797;
}

.numbers-info img {
  padding: 2px 0 0 5px;
}

.numbers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-family: 'Circular Std Book';
}

.topic-searches {
  color: #6870f6;
}

.topic-visits {
  color: #e47b5e;
}

.topic-average {
  color: #89b999;
}

.topic-chart p{
  font-family: 'Inter UI';
  font-weight: 500;
  font-size: 18px;
  color: #3a4357;
  margin-bottom: 20px;

}

.topic-result-actions {
  display: flex;
}

.topic-result-img {
  align-self: center;
}

.no-data-found {
  font-family: 'Inter UI';
  font-weight: 500;
  font-size: 24px;
  color: #7a8797;
  margin-bottom: 130px;
}