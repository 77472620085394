.custom-message {
  position: relative;
}

.message-box {
  position: absolute;
  background: #141414;
  padding: 10px 20px 15px;
  border-radius: 5px;
  box-shadow: 1.3px 0.3px 15px rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 230px;
  color: #ccc;
  z-index: 100;
}

.message-text {
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
}

.message-arrow {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #141414;
  top: -31px;
  left: -3px;
}
