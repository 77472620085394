@import "../_global-vars.css";

.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.3rem;
  font-size: 15px;
  color: var(--color-dark-text);
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid var(--color-inactive-input);
  border-radius: 7px;
  background: white;
  padding: 8px 10px;
  color: var(--color-light-text);
  /* height: 35px; */
  /* font-size: 14px; */
}

.form-control input::placeholder {
  color: var(--color-light-text);
  font-size: 14px;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: white;
  border-color: var(--color-active-input);
}

.form-control--invalid label,
.form-control--invalid p {
  color: var(--color-error-input);
  margin: 3px 0 5px;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: var(--color-error-input);
}

.form-control p {
  font-size: 13px;
  margin-bottom: 5px;
}

.form-control--collapsed {
  margin: 0;
  width: 100%;
}

.form-control--collapsed:first-child {
  width: 100%;
}
