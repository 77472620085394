.performanceDashboard {
  display:flex;
  width: 100%;
  min-height: 100%;
  background-color: #FAFBFF;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 80px;
  position: relative;
 
}

.stats-wrapper {
  margin: 0 30px 30px;
}

.performanceListWrapper {
  margin: 0 30px 30px;
  padding: 50px 30px 30px;
  background-color: white;
  border-radius: 20px;
  /* min-width: 1495px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26); */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.mainList {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas:
    "head"
    "card"
}

.listHead {
  display: grid;
  grid-area: head;
  color: #6F767E;
  padding: 15px 0;
  align-items: center;
  font-size: 13px;
  font-family: var(--default-font);
  font-weight: 600;
  grid-template-columns: 50px 370px repeat(8, 1fr) 50px;
  /* grid-template-columns: 50px 350px 100px 100px 100px 100px 100px 100px 100px 100px 50px; */
  grid-template-areas:
  "select select-title health-title opportunity-title visits-title keywords-title  rank-title score-title rating-title  writer-title ."
}

/* health, opportunity, visits, keywords, avg rank, score, rating, writer */


/* header areas */
.selectCard {
  grid-area: select;
}

.selectAllTitle {
  grid-area: select-title;
}

.healthTitle {
  grid-area: health-title;
}

.scoreTitle {
  grid-area: score-title;
}

.visitsTitle {
  grid-area: visits-title;
}

.keywordsTitle {
  grid-area: keywords-title;
}

.opportunityTitle {
  grid-area: opportunity-title;
}

.rankTitle {
  grid-area: rank-title;
}

.ratingTitle {
  grid-area: rating-title;
}

.writerTitle {
  grid-area: writer-title;
  /* display: flex;
  justify-content: flex-end; */
}

/* card body areas */
.performanceCard {
  display: grid;
  padding: 15px 0;
  grid-area: card;
  border-radius: 0px;
  border-top: solid 1px #F4F4F4;
  /* grid-template-columns: 50px 100px 250px 100px 100px 100px 100px 100px 100px 100px 100px 50px; */
  grid-template-columns: 50px 100px 270px repeat(8, 1fr) 50px;
  grid-template-areas:
  "checkbox image article-info health-info opportunity-info visits-info  keywords-info rank-info  score-info rating-info  writer-info menu-items"
}

/* health, opportunity, visits, keywords, avg rank, score, rating, writer */

.performanceCard:hover {
  background: #F4F4F4;
  border-radius: 8px;
  border-top: solid 1px transparent;
}

.cardCheck {
  grid-area: checkbox;
}

.imageWrapper {
  width: 80px;
  height: 57px;
  margin: 0;
  padding: 0;
  background: #6F767E;
  overflow: hidden;
  border-radius: 10px;
}

.cardImage {
  grid-area: image;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.articleInfo {
  display: grid;
  align-items: center;
  grid-area: article-info;
  grid-template-columns: 250px;
  grid-template-rows: 15px 50px;
  grid-template-areas:
  "date"
  "title"
}

.infoDate {
  grid-area: date;
  color: #8F95B2;
  align-items: center;
  font-size: 10px;
  font-family: var(--default-font);
  font-weight: bold;
}

.infoTitle {
  grid-area: title;
  color: #1A1D1F;
  align-items: center;
  font-weight: bold;
  padding-right: 5px;
  font-size: 14px;
  font-family: var(--default-font);
  cursor: pointer;
}

.infoTitle:hover {
  color: #6C5DD3;
}

.infoTopic {
  grid-area: topic;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #8F95B2;
  font-size: 13px;
  font-weight: 600;
  font-family: var(--default-font);
}
.infoRank {
  grid-area: topic;
  color:#6C5DD3;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  font-family: var(--default-font);
  margin-left: 20px;
}

.healthInfo {
  grid-area: health-info;
  display: flex;
  align-items: center;
}

.circleWrapper {
  width: 55px;
  height: 55px;
  position: relative;
}

.healthScore {
  position: absolute;
  transform: translateY(-28%);
  font-size: 12px;
  font-weight: 600;
  color: #11142D;
  font-family: var(--default-font);
}

.scoreInfo {
  grid-area: score-info;
  display: flex;
  align-items: center;
}

.scoreValue {
  font-size: 15px;
  color: #1A1D1F;
  font-weight: 600;
  font-family: var(--default-font);
}

.visitsInfo {
  grid-area: visits-info;
  display: flex;
  align-items: center;
}

.centerLabels {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centerLabelsOffset {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -20px;
}
.visitsTrend {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.visitsTrendValue {
  margin-left: 4px;
  font-size: 12px;
  color: #83BF6E;
  font-weight: 900;
  font-family: var(--default-font);
}
.infoTag {
  display: flex;
  justify-content: center;
  padding: 3px 5px;
  width: 60px;
  border-radius: 5px;
  font-size: 15px;
  color: #1A1D1F;
  font-weight: 600;
  font-family: var(--default-font);
}
.keywordsInfo {
  grid-area: keywords-info;
  display: flex;
  align-items: center;
}

.opportunityInfo {
  grid-area: opportunity-info;
  display: flex;
  align-items: center;
}

.rankInfo {
  grid-area: rank-info;
  display: flex;
  align-items: center;
}

.ratingInfo {
  grid-area: rating-info;
  display: flex;
  align-items: center;
}

.writerInfo {
  grid-area: writer-info;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
}

.writerTag {
  background: #EFEFEF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  font-size: 14px;
  color:#272B30;
  font-weight: 900;
  font-family: var(--default-font);
  align-items: center;
  justify-content: center;
}
.writerRating {
  display: flex;
  grid-area: writer-rating;
  align-items: flex-end;
  width: 55px;
  justify-content: center;
}
.rating {
  display: flex;
  align-items: center;
}
.ratingValue {
  font-size: 12px;
  color: #1A1D1F;
  font-weight: 600;
  font-family: var(--default-font);
  margin-left: 5px;
}

.ratingValueEmpty {
  font-size: 12px;
  color: #6F767E;
  font-weight: 600;
  font-family: var(--default-font);
  margin-left: 5px;
}
.trafficInfo {
  grid-area: traffic-info;
  display: flex;
  align-items: flex-end;
}

.trafficValue {
  font-size: 12px;
  color: #8F95B2;
  font-weight: 600;
  font-family: var(--default-font);
}

.menuItems {
  display: flex;
  grid-area: menu-items;
  margin-left: 20px;
  justify-content: flex-end;
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: space-between; */
  
}

.menuIcons {
  /* display:flex; */
}

.iconWrapper {
  cursor: pointer;
  margin: 10px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.performance-stats {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.performance-small-cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  min-width: 36%;
}