.topic-serp-content-row {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 15px 20px;
}

.serp-rank {
  background-color: #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #75819b;
  font-weight: 900;
}

.serp-competitor {
  color: #75819b;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
}

.serp-comp-image {
  border-radius: 50%;
}

.serp-keywords-wrapper {
  display: flex;
  justify-content: flex-end;
}

.serp-keywords {
  background-color: #ffffff;
  border-radius: 14px;
  width: 80px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #75819b;
  font-weight: 900;
}
