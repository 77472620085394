.radar-chart-placeholder {
  position: relative;
  height: 300px;
}

.radar-chart-placeholder-messages {
  display: flex;
  bottom: 10px;
  justify-content: center;
  height: 100%;
  align-items: flex-end;
}

.radar-chart-placeholder-typed {
  font-family: "Inter UI";
  color: #75819b;
  font-weight: 500;
  padding-bottom: 28px;
}
