.bar-chart-wrapper {
  display: flex;
}

.bar-chart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 4px;
  cursor: pointer;
}

.bar-name {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
}
