.custom-menu {
  font-size: 28px;
  font-weight: bold;
  position: relative;
  width: 35px;
  text-align: center;
  cursor: pointer;
}

.custom-menu-display {
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 13px;
  position: absolute;
  width: 210px;
  z-index: 10;
  top: 32px;
  left: -120px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: left;
}

.option {
  color: #384359;
}

.option:last-child {
  border-top: solid 1px #f2f0f9;
  color: #d20000;
}

.option:first-child {
  border-top: none;
  color: #384359;
}

.option-item {
  padding: 5px 10px;
}

.option-item:hover {
  border: none;
  background: #f2f0f9;
  border-radius: 6px;
}
