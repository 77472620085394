.menu-item {
  background-color: transparent;
  border: none;
  border-radius: 0.4rem;
  /* color: #6870f6; */
  color: #b5b5c3;
  height: 1.75rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  width: 1.75rem;
  cursor: pointer;
  position: relative;
}

.menu-item svg {
  fill: currentColor;
  height: 100%;
  width: 100%;
}

.menu-item:hover,
.is-active {
  /* background-color: #6870f6; */
  background-color: #b5b5c3;
  color: #fff;
}
