.skeleton-card-category {
  display: flex;
  align-items: center;
  border-top: solid 1px #D7E1EA;
  padding: 20px 10px;
  box-sizing: border-box;
}

.skeleton-card-circle {
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.skeleton-bar-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.skeleton-bar-bars,
.skeleton-title-bars {
  display: flex;
  flex-direction: column;
}

.skeleton-bar-bars .react-loading-skeleton,
.skeleton-title-bars .react-loading-skeleton {
  border-radius: 25px;
  margin-top: 7px;
}
