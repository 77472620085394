.table-select-options .react-select-container {
  /* width: 160px; */
}

.table-select-options-restricted .react-select-container {
  width: 100px;
}

.table-select-options .react-select__control {
  border: none !important;
  max-width: 160px;
  background: transparent;
}

.table-select-options .react-select__value-container {
  padding: 0;
}

.table-select-options .react-select__indicators {
  display: none !important;
}

.table-select-options .react-select__menu {
  width: 210px;
  padding: 5px 10px;
  border: none !important;
  font-size: 13px;
  border-radius: 10px;
  outline: none !important;
  color: #384359;
}

.table-select-options .react-select__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: none;
  color: #384359;
}

.table-select-options .react-select__option:hover {
  background: #f2f0f9;
  border-radius: 6px;
  color: #384359;
}

/* select component in popup */
.table-select-options__popup .react-select-container {
  width: 250px;
}
.table-select-options__popup {
  font-size: 14px;
  color: #384359;
}
.table-select-options__popup .react-select__menu {
  padding: 5px 10px;
  border: none !important;
  border-radius: 10px;
  outline: none !important;
}

.table-select-options__popup .react-select__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: white;
  color: #384359;
}

.table-select-options__popup .react-select__option:hover {
  background: #f2f0f9;
  border-radius: 6px;
}

.table-select-options .react-select__single-value {
  color: #75819b !important;
  font-family: "Inter UI";
  font-weight: 500;
}

.table-select-options-red .react-select__single-value {
  color: #c83e4d !important;
  font-family: "Inter UI";
  font-weight: 500;
}

.read-only {
  color: #75819b !important;
  font-family: "Inter UI";
  font-weight: 500;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
