.range-labels {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.label-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.label-text {
  font-size: 15px;
  font-family: "Inter UI";
  font-weight: 500;
  color: var(--color-dark-text);
  margin-right: 10px;
}

.range-slider {
  width: 60%;
}

.range-slider .MuiSlider-rail {
  height: 5px;
  background: var(--color-light-text);
}

.range-slider .MuiSlider-track {
  height: 5px;
  background: var(--color-active-input);
}

.range-slider .MuiSlider-thumb {
  margin-top: -3px;
  background: var(--color-active-input);
}

.similarity-filter-btns {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.similarity-filter-btns button {
  margin: 0;
  max-width: 190px;
}
