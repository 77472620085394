.dynamic-pill {
  background: rgba(228, 228, 228, .25);
  padding: 5px 10px;
  border-radius: 62px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  width: 43px;
  height: 32px;
}

.article-rating {
  background: #75819b;
  color: white;
  border-radius: 50px;
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  right: 0;
  top: -7px;
  padding: 1px 4px;
}
