.recharts-wrapper.radar-chart{
  width: 388px !important;
  height: 250px !important;
  left: 42px !important;
  top: 68px !important;
}

.chart-custom-labels {
  position: relative;
  top: 0px;
  left: 30px;
  font-family: "Graphik";
  font-weight: 600;
  color: #3A4257;
  font-size: 14px;
  z-index: 99;
  text-align: center;
  max-width: 420px;
}

.chart-metric {
  /* font-family: "Inter UI";
  font-weight: 500;
  color: #d9e1e9;
  font-size: 14px; */
  font-family: "Poppins";
  font-weight: 600;
  color: #748094;
  font-size: 12px;
  padding-top: 5px;
}

.topic-coverage {
  position: absolute;
  top: 37px;
  left: 150px;
}

.title-optimization {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 90px;
  left: 290px;
  width: 85px;
}

.headings-optimization {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 90px;
  left: 11px;
  width: 120px;
}

.chart-wordcount {
  position: absolute;
  top: 206px;
  left: 23px;
}

.topic-density {
  position: absolute;
  top: 263px;
  left: 157px;
}

.chart-images {
  position: absolute;
  top: 206px;
  left: 292px;
}
