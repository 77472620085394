.brief-content {
  padding: 30px;
  max-width: 470px;
}

.brief-project-name {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.brief-section {
  padding-bottom: 15px;
}

.brief-description {
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
  color: #384259;
}

.brief-tech-feedback {
  margin-top: 10px;
}

.update-feedback-btn {
  margin-top: -10px;
  padding: 0 100px;
}

.feedback-form {
  margin-top: -10px;
}

.feedback-form .form-control {
  margin-bottom: 3px;
}

.feedback-form .form-control textarea {
  font-size: 14px;
}
