.topic-research-card {
  /* display: flex; */
}
.cards-wrapper {
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 40px 0;
  justify-content: space-between;
  flex-wrap: wrap;
}

.research-submit {
  width: 100%;
  max-width: 446px;
}

.info-cards {
  display: flex;
  justify-content: space-between;
  background: #f9f9f9;
  /* flex-wrap: wrap; */
  gap: 20px;
  width: 100%;
  padding: 0 20px;
  border-radius: 15px;
}

.card-content {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 18px;
  border-radius: 5px;
  /* max-width: 174px; */
}

.card-content-inverted {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  padding: 10px 18px;
  border-radius: 5px;
  max-width: 130px;
}

.intent {
  text-transform: uppercase;
  font-family: "Inter UI";
  color: #75819b;
  font-weight: 800;
  font-size: 12px;
  margin-left: 15px;
}

.trend {
  text-transform: uppercase;
  font-family: "Inter UI";
  color: #75819b;
  font-weight: 800;
  font-size: 12px;
  margin-left: 15px;
}

.research-chart-data {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.results-chart-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 1339px) {
  .cards-wrapper {
    flex-wrap: nowrap;
  }

  .info-cards {
    width: 50%;
  }

  .research-chart-data {
    width: 50%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
