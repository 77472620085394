.competition {
  display: flex;
  flex-direction: column;
}

.competiton-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.competition h3 {
  font-family: 'Graphik';
  font-weight: 600;
  color:#384259;
  padding: 25px 0;
}

.competition-sub {
  font-family: 'Inter UI';
  font-weight: 500;
  color:#384359;
  font-size: 14px;
  padding: 0 20px 20px;
}

.top-numbers {
  display: flex;
  width: 100%;
  padding: 0 20px;
  justify-content: space-around;
  padding-bottom: 20px;
}

.competition-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
  font-family: "Circular Std Book";
}
.competition-numbers-info {
  display: flex;
  align-items: center;
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 12px;
  color: #384259;
}

.competition-numbers-label {
  padding-right: 5px;
}

.info-words {
  color: #6870f6;
}

.info-images {
  color: #e47b5e;
}