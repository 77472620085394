.sidebar-right {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-left: solid 1px #ededed;
  min-width: 470px;
}

.sidebar-header {
  background-color: #fafafa;
}

.sidebar-comments {
  display: none;
}

.sidebar-comments-active {
  display: block;
  padding: 0 15px;
}

.ck.ck-sidebar {
  position: relative;
  z-index: 1;
  transition: min-height 0.25s ease;
  outline: 0;
  overflow-y: hidden;
}

.sidebar-content {
  /* height: calc(100% - 136px); */
  /* height: 100vh; */
}
