@import "../_global-vars.css";

.category-editor-wrapper {
  width: 100%;
  height: calc(100vh - 144px); 
  
}

.category-editor-content {
  margin: 0 auto;
  margin-top: 20px;
  width: 90%;
}

.category-top-info {
  display: flex;
  justify-content: space-between;
}

.meta-input-area {
  width: 100%;
}

.counters-area {
  display: flex;
  width: 50%;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 58px;
  padding-top: 15px;
}

.bottom-header {
  width: 100%;
  height: 6px;
  background-color: #fafbff;
}

.category-editor-head {
  display: flex;
}

.category-editor-copy {
  margin-bottom: -13px;
  margin-left: 5px;
  cursor: pointer;
}

.category-editor-title {
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 15px;
  font-size: 15px;
  color: var(--color-dark-text);
}
