.text-title {
  font-size: 12px;
  font-weight: 200;
  color: #6F767E;
  font-family: var(--default-font);
  margin-right: 3px;
}

.info-icon {
  margin-top: -4px;
}

.parent-wrapper {
  display: flex;
}
