.step3-wrapper {
  min-height: 260px;
}
.add-new-user-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid 1px gainsboro;
  border-style: dashed;
  border-radius: 30px;
  padding: 3px 10px;
  cursor: pointer;
}

.add-new-user-text {
  color: #6f767e;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
