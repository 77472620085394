.sidebar-left {
  width: 85px;
  height: 100vh;
  background-color: white;
  border-right: solid 1px #D8D8D8;
  position: absolute;
  z-index: 99;
  transition: width 0.1s;
  /* transition-timing-function: ease-out; */
}

.sidebar-backdrop {
  background: transparent;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 90;
}

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}

.sidebar-logo-area {
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.sidebar-logo-wrapper {
  display: flex;
  justify-content: center;
  height: 43px;
  cursor: pointer;
}

.sidebar-control {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px 4px;
  margin-bottom: 30px;
}

.toggle-icon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.5s, transform 0.1s;
  cursor: pointer;
}
.toggle-icon-wrapper-closed {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.5s, transform 0.1s;
  transform:rotate(-180deg);
  cursor: pointer;
}

.toggle-icon-wrapper:hover,
.toggle-icon-wrapper-closed:hover
 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgb(117, 129, 155, 0.2);;
}

.top-icons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
}

.bottom-icons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-end;
  padding-bottom: 30px;
}

.sidebar-centered-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.dynamic-btn-wrapper {
  display: flex;
  justify-content: flex-start;
  min-width: 85%;
}

.dynamic-icon-expanded {
  padding: 25px;
  min-width: 100%;
  border-radius: 12px;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.dynamic-icon-expanded:hover {
  padding: 25px;
  min-width: 100%;
  border-radius: 12px;
  background: #6C5DD3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.expanded-menu-text {
  color: rgb(117, 129, 155);
  font-family: "Inter UI";
  font-weight: 500;
  font-size: 13px;
  margin-left: 15px;
}

.dynamic-icon {
  max-width: 90%;
  padding: 25px;
  border-radius: 12px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dynamic-icon:hover {
  max-width: 90%;
  padding: 25px;
  border-radius: 12px;
  background: #6C5DD3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dynamic-icon-bottom {
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 12px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dynamic-icon-bottom-expanded {
  padding: 12px 25px;
  min-width: 100%;
  border-radius: 12px;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.small-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.bottom-separator {
  width: 60%;
  display: flex;
  height: 1px;
  background: #D8D8D8;
  margin-bottom: 10px;
}

.bottom-separator-expanded {
  width: 80%;
  display: flex;
  height: 1px;
  background: #D8D8D8;
  margin-bottom: 10px;
}

/* .sidebar-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 220px;
  justify-content: flex-start;
  margin-top: 185px;
}

.sidebar-icon span {
  height: 30px;
  width: 30px;
  padding: 7px;
  background-color: #dfe9ff;
  border-radius: 50%;
  display: inline-block;
}

.sidebar-icon {
  display: flex;
  position: relative;
  cursor: pointer;
  margin-bottom: 40px;
}

.sidebar-tooltip {
  display: none;
  background: white;
  padding: 10px 20px;
  position: absolute;
  left: 120px;
  top: 15px;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  z-index: 99;
  border-radius: 5px;
  box-shadow: 1.3px 0.3px 15px rgba(0, 0, 0, 0.2);
  font-family: "Inter UI";
  font-weight: normal;
  font-size: 14px;
  color: #384359;
}

.sidebar-tooltip:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
  left: -8px;
  top: 11px;
}

.sidebar-tooltip.export-icon {
  left: 107px;
  top: 11px;
}

.sidebar-tooltip.home-icon {
  left: 140px;
  top: 14px;
}

.sidebar-tooltip.sign-out-icon {
  left: 91px;
  top: 11px;
}

.sidebar-tooltip.performance-icon {
  left: 140px;
  top: 11px;
}

.sidebar-tooltip.eshop-icon {
  left: 156px;
  top: 11px;
}

.sidebar-icon.plus-icon:hover .sidebar-tooltip.plus-icon {
  display: block;
}

.sidebar-icon.home-icon:hover .sidebar-tooltip.home-icon {
  display: block;
}

.sidebar-icon.export-icon:hover .sidebar-tooltip.export-icon {
  display: block;
}

.sidebar-icon.sign-out-icon:hover .sidebar-tooltip.sign-out-icon {
  display: block;
}

.sidebar-icon.performance-icon:hover .sidebar-tooltip.performance-icon {
  display: block;
}

.sidebar-icon.eshop-icon:hover .sidebar-tooltip.eshop-icon {
  display: block;
} */

.dev-console {
  text-align: center;
  background: #6b14b1;
  color: white;
  padding: 32px 5px;
  width: 100%;
  justify-self: flex-end;
  font-size: 12px;
}
