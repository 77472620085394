.template {
  display: flex;
  flex-direction: column;
  align-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
}

.template-body {
  flex: 1 0 auto;
}