.register-form {
  list-style: none;
  width: 400px;
}

.email-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 10px;
}

.sign-up-link {
  font-size: 14px;
  color: var(--color-light-text);
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.error-message-info {
  padding: 10px 0;
}
