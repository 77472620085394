.sliders-area {
  padding-bottom: 31px;
}

.sliders-label {
  font-weight: bold;
  margin-bottom: 0.3rem;
  font-size: 15px;
  color: var(--color-dark-text);
}

.slider-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider-wrapper {
  width: 180px;
}

.slider-text-end,
.slider-text-start {
  width: 70px;
  font-weight: bold;
  color: var(--color-light-text);
}

.slider-text-end {
  text-align: right;
}

.sliders-area .MuiSlider-rail {
  height: 5px;
}

.sliders-area .MuiSlider-track {
  height: 5px;
}

.sliders-area .MuiSlider-thumb {
  margin-top: -3px;
}

/* disabled sliders  */

.sliders-area__disabled {
  padding-bottom: 20px;
}

.sliders-label__disabled {
  font-weight: bold;
  margin-bottom: 0.3rem;
  font-size: 14px;
  color: var(--color-dark-text);
}

.slider-text-start__disabled,
.slider-text-end__disabled {
  width: 70px;

  font-size: 13px;
  font-weight: bold;
}

.sliders-area__disabled .MuiSlider-rail {
  height: 2px;
}

.sliders-area__disabled .MuiSlider-track {
  height: 2px;
}

.sliders-area__disabled .slider-wrapper {
  height: 20px;
}
