.form-wrapper {
  padding: 0 10px; /* scroolbar space */
}

.new-entry-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.new-entry-form .form-control {
  margin: 0;
}

.select-inputs {
  display: flex;
}

.left-column {
  width: 50%;
  margin-right: 10px;
  font-size: 14px;
}

.right-column {
  width: 50%;
  margin-left: 10px;
  font-size: 14px;
}

.native {
  margin-bottom: 20px;
}

.left-column .react-select-container {
  margin: 20px 0;
}
.right-column .react-select-container {
  margin: 20px 0;
}

.new-entry-form .react-select__menu {
  padding: 5px 10px;
  border: none !important;
  border-radius: 10px;
  outline: none !important;
}

.new-entry-form .required__control {
  min-height: 35px!important;
  border: solid 1px var(--color-inactive-input) !important;
  border-radius: 7px!important;
}

.new-entry-form .required__control--is-focused {
  border: solid 1px var(--color-active-input) !important;
  box-shadow: none!important;
}

.new-entry-form .required__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: white;
  color: #384359;
}

.new-entry-form .required__option:hover {
  background: #f2f0f9;
  border-radius: 6px;
}

.new-entry-form .required__placeholder {
  color: var(--color-error-input);
}

.new-entry-form .react-select__option {
  padding: 5px 10px;
  margin: 2px 0;
  background: white;
  color: #384359;
}

.new-entry-form .react-select__option:hover {
  background: #f2f0f9;
  border-radius: 6px;
}

.date-wrapper {
  margin: 20px 0;
  height: 38px;
  align-items: center;
}

.date-input-container {
  display: flex;
  padding-top: 6px;
  align-items: center;
  color: #384359;
  justify-content: space-between;
}

.date-input-label {
  font-size: 15px;
  font-weight: 600;
  margin-right: 20px;
}

.additional-info-input {
  margin-top: 18px;
}
